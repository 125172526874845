<template>
  <div class="rechargehistory">
    <van-nav-bar
      title="充值记录"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-list
      v-if="!nondata"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell-group :border="false" v-for="(item, index) in list" :key="index">
        <van-cell :border="false" title-class="title_class">
          <template #title>
            联惠金充值
          </template>
          <template #right-icon>
            <span :class="item.status_class">{{item.status_str}}</span>
          </template>
        </van-cell>
        <van-cell :border="false">
          <template #title>
            充值单号：{{item.logno}}
          </template>
          <template #right-icon>
            <span>&yen;{{item.money}}</span>
          </template>
        </van-cell>
        <van-cell :border="false">
          <template #title>
            {{item.createtime_str}}
          </template>
        </van-cell>
        <van-cell :border="false" v-if="item.remark">
          <template #title>
            <div class="fail_respon">{{item.remark}}</div>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
    <van-empty v-if="nondata" description="暂无记录" />
  </div>
</template>
<script>
export default {
  name: "RechargeHistory",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      list: [],
      CurrOffset: 0,
      CurrSize: 20,
      loading: false, // 本次加载是否完毕
      finished: false, // 是否查询完成
      nondata: false
    }
  },
  mounted() {
    console.log('success');
  },
  methods: {
    onLoad() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "team/user/get_recharge_credit5",
          _that.$qs.stringify({
            size: _that.CurrSize,
            offset: _that.CurrOffset,
          })
        )
        .then(function(response) {
          console.log(response.data.data, _that.CurrSize);
          _that.loading = false;
          if (response.data.code == 100000) {
            _that.nondata = false;
            if (response.data.data && response.data.data.length > 0) {
              if (_that.CurrOffset == 0) {
                _that.list = response.data.data;
              }else{
                response.data.data.forEach(item => {
                  _that.list.push(item);
                })
              }

              if (response.data.data.length >= _that.CurrSize) {
                _that.CurrOffset += _that.CurrSize;
                _that.finished = false; // 是否查询完成
              }else {
                _that.CurrOffset += response.data.data.length;
                _that.finished = true; // 是否查询完成
              }
            }else {
              _that.finished = true; // 是否查询完成
            }
          }else {
            _that.finished = true; // 是否查询完成
            if (_that.CurrOffset == 0) {
              _that.$toast(response.data.msg ? response.data.msg : "暂无记录");
              _that.nondata = true;
            }
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    }
  }
}
</script>
<style lang="less">
.rechargehistory {
  width: 100%;
  min-height: 100%;
  background-color: #ededed;
  padding-bottom: 60px;
  .van-nav-bar {
    .van-icon {
      color: #333;
    }
  }
  .van-list {
    background-color: #fff;
    .van-cell-group {
      padding: 0;
      .van-cell {
        .van-cell__title {
          flex: 1;
          text-align: left;
        }
        &:last-of-type {
          padding-top: 0;
          border-bottom: 1px solid #eee;
        }
        span {
          margin-left: 5px;
        }
        .fail_class {
          color:#ff976a;
        }
        .success_class {
          color: #07c160;
        }
        .fail_respon {
          color: #EE0A24;
          line-height: 20px;
        }
      }
    }
  }
}
</style>